import Image from 'next/image';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import { NextLink } from '@/atoms';

import { ConditionalWrapper } from '@/components/hoc';
import { removeBaseURL } from '@/lib/utils';

import type { TypeSliderShadowBoxProps } from './types';

const SliderShadowBoxCard = ({ card }: { card: TypeSliderShadowBoxProps }) => {
  const Comp: React.ElementType = card?.link?.url ? 'a' : 'div';

  return (
    <ConditionalWrapper
      condition={Boolean(card?.link?.url)}
      wrapper={(children) => (
        <NextLink href={removeBaseURL(card?.link?.url ?? '/')} passHref>
          {children}
        </NextLink>
      )}
    >
      <Comp>
        <div
          className="absolute bottom-0 flex w-full justify-center px-2 py-1"
          style={{ backgroundColor: card.backgroundColor }}
        >
          <p
            className="u-subtitle leading-none"
            style={{
              fontSize: card?.fontSize ?? 25,
              color: card.textColor,
            }}
          >
            {card.title}
          </p>
        </div>
        <Image
          src={card.image.sourceUrl}
          width={card.image.mediaDetails.width}
          height={card.image.mediaDetails.height}
          alt={card.image.altText}
          className="w-full object-cover object-center"
          sizes="(max-width: 450px) 100vw, (max-width: 660px) 50vw, 33vw"
        />
      </Comp>
    </ConditionalWrapper>
  );
};

export const SliderShadowBox = ({
  cards,
}: {
  cards: TypeSliderShadowBoxProps[];
}) => (
  <>
    {/**
     * TODO: La versión que tenemos de Splide no tiene el prop Children ya que en React 17 se añadia automaticamente pero en la 18 no
     * Cuando actualicemos Splide a la última versión, podemos quitar el @ts-ignore
     * @eslint-disable-next-line @typescript-eslint/ban-ts-comment
     * @ts-expect-error types are not for react 18 */}
    <Splide
      options={{
        pagination: cards.length > 3,
        drag: cards.length > 3,
        arrows: false,
        type: 'slide',
        perPage: 3,
        perMove: 3,
        gap: 22,
        width: 1160,
        classes: {
          pagination:
            'inset-x-0 mt-2 mx-auto flex items-center justify-center overflow-x-auto',
          page: 'border-2 border-typo-secondary rounded-full h-3 inline-block m-1.5 opacity-70 p-0 w-3 transition-transform duration-200 ease-linear [&.is-active]:bg-typo-secondary',
        },
        breakpoints: {
          450: {
            perPage: 1,
            pagination: true,
            drag: true,
          },
          660: {
            perPage: 2,
            pagination: true,
            drag: true,
          },
        },
      }}
    >
      {cards.map((card, index) => (
        <SplideSlide key={index}>
          <SliderShadowBoxCard card={card} />
        </SplideSlide>
      ))}
    </Splide>
  </>
);
